/* ScrollPanelDemo.css */

.scrollpanel-demo .p-scrollpanel p {
    padding: .5rem;
    line-height: 1.5;
    margin: 0;
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
    border-right: 10px solid var(--surface-b);
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar {
    background-color: var(--primary-color);
    opacity: 1;
    transition: background-color .2s;
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
    background-color: #007ad9;
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-wrapper {
    border-right: 10px solid var(--surface-b);
    border-bottom: 10px solid var(--surface-b);
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-bar {
    background-color: var(--surface-d);
    border-radius: 0;
    opacity: 1;
    transition: background-color .2s;
}

.scrollpanel-demo .p-col-12 {
    padding: 2rem;
}
                 
.p-scrollpanel .p-scrollpanel-bar {
    background: #007ad9;
    border: 0 none;
}

.p-scrollpanel-content {
    height: calc(100% + 18px);
    width: calc(100% + 18px);
    padding: 0 5px 5px 0;
    position: relative;
    overflow: scroll;
    box-sizing: border-box;
}