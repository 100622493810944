.datatable-responsive-demo
  .p-datatable-responsive-demo
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

.datatable-responsive-demo .row-bordo {
  background-color: #ba6187 !important;
}

.datatable-responsive-demo .row-rojo {
  background-color: red !important;
}

.datatable-responsive-demo .row-amarillo {
  background-color: yellow !important;
}

.datatable-responsive-demo .row-verde {
  background-color: green !important;
}

.datatable-responsive-demo .row-negro {
  background-color: 	#808080 !important;
}

@media screen and (max-width: 599px) {
  .pdatatable {
    font-size: xx-small;
    margin-top: 28vw;
  }
  .pdatatable2 {
    font-size: xx-small;
    margin-top: 20vw;
  }
  .ptoolbar {
    position: fixed;
    left: auto;
    top: 15vw;
    right: 0;
    width: 100%;
    padding: 1vw !important;
    z-index: 1000;
  }
  .colIndicativo {
    font-size: xx-small;
    padding: 0;
    color: black;
    font-weight: 700;
  }
  .colAfiliado {
    font-size: xx-small;
    padding: 0;
    color: black;
    font-weight: 700;
    font-weight: bold;
  }
  .colColor {
    font-size: xx-small;
    padding: 0;
    color: black;
    font-weight: 700;
  }
  .colGrupo {
    font-size: xx-small;
    padding: 0;
    color: black;
    font-weight: 700;
  }
  .colIndicativo {
    font-size: xx-small;
    width: 3%;
    padding-left: 2em;
    color: black;
    font-weight: 700;
  }
  .colColor {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: 700;
  }
  .colGrupo {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: 700;
  }
  .colDomicilio {
    font-size: xx-small;
    width: 18%;
    color: black;
    font-weight: 700;
  }
  .colLocalidad {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: 700;
  }
  .colNombre {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: 700;
  }
  .colMovil {
    font-size: xx-small;
    color: black;
    font-weight: 700;
  }
  .colEdad {
    font-size: xx-small;
    width: 3%;
    color: black;
    font-weight: 700;
  }
  .colEstado {
    font-size: xx-small;
    width: 7%;
    color: black;
    font-weight: 700;
  }
  .colHorarios {
    font-size: xx-small;
    color: black;
    font-weight: 700;
  }
  .colBotones {
    font-size: xx-small;
    width: 10%;
  }
}
/* sm*/
@media screen and (min-width: 600px) and (max-width: 959px) {
  .pdatatable {
    font-size: xx-small;
    margin-top: 10vw;
  }
  .ptoolbar {
    position: fixed;
    left: auto;
    top: 6.5vw;
    right: 0;
    width: 100%;
    padding: 1vw !important;
    z-index: 1000;
  }
  .colIndicativo {
    font-size: xx-small;
    width: 3%;
    padding-left: 2em;
    color: black;
    font-weight: 700;
  }
  .colAfiliado {
    font-size: xx-small;
    width: 8%;
    padding: 0;
    color: black;
    font-weight: 700;
    font-weight: bold;
  }
  .colColor {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: 700;
  }
  .colGrupo {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: 700;
  }
  .colDomicilio {
    font-size: xx-small;
    width: 18%;
    color: black;
    font-weight: 700;
  }
  .colLocalidad {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: 700;
  }
  .colNombre {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: 700;
  }
  .colMovil {
    font-size: xx-small;
    color: black;
    font-weight: 700;
  }
  .colEdad {
    font-size: xx-small;
    width: 3%;
    color: black;
    font-weight: 700;
  }
  .colEstado {
    font-size: xx-small;
    width: 7%;
    color: black;
    font-weight: 700;
  }
  .colHorarios {
    font-size: xx-small;
    color: black;
    font-weight: 700;
  }
  .colBotones {
    font-size: xx-small;
    width: 10%;
  }
}

/* md */
@media screen and (min-width: 960px) and (max-width: 1279px) {
  .pdatatable {
    font-size: xx-small;
    margin-top: 4.5vw;
  }
  .ptoolbar {
    position: fixed;
    left: auto;
    top: 4.5vw;
    right: 0;
    width: 100%;
    padding: 1vw !important;
    z-index: 1000;
  }
  .colIndicativo {
    font-size: xx-small;
    width: 3%;
    padding-left: 2em;
    color: black;
    font-weight: 700;
  }
  .colAfiliado {
    font-size: xx-small;
    width: 8%;
    padding: 0;
    color: black;
    font-weight: 700;
    font-weight: bold;
  }
  .colColor {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: 700;
  }
  .colGrupo {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: 700;
  }
  .colDomicilio {
    font-size: xx-small;
    width: 18%;
    color: black;
    font-weight: 700;
  }
  .colLocalidad {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: 700;
  }
  .colNombre {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: 700;
  }
  .colMovil {
    font-size: xx-small;
    color: black;
    font-weight: 700;
  }
  .colEdad {
    font-size: xx-small;
    width: 3%;
    color: black;
    font-weight: 700;
  }
  .colEstado {
    font-size: xx-small;
    width: 7%;
    color: black;
    font-weight: 700;
  }
  .colHorarios {
    font-size: xx-small;
    color: black;
    font-weight: 700;
  }
  .colBotones {
    font-size: xx-small;
    width: 10%;
  }
}

/* lg */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .pdatatable {
    font-size: xx-small;
    margin-top: 4.4vw;
  }
  .ptoolbar {
    position: fixed;
    left: auto;
    top: 3.8vw;
    right: 0;
    width: 100%;
    padding: 1vw !important;
    z-index: 1000;
  }
  .colIndicativo {
    font-size: xx-small;
    width: 3%;
    padding-left: 2em;
    color: black;
    font-weight: bold;
  }
  .colAfiliado {
    font-size: xx-small;
    width: 8%;
    padding: 0;
    color: black;
    font-weight: 700;
    font-weight: bold;
  }
  .colColor {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: bold;
  }
  .colGrupo {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: bold;
  }
  .colDomicilio {
    font-size: xx-small;
    width: 25%;
    color: black;
    font-weight: bold;
  }
  .colLocalidad {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: bold;
  }
  .colNombre {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: bold;
  }
  .colMovil {
    font-size: xx-small;
    color: black;
    font-weight: bold;
  }
  .colEdad {
    font-size: xx-small;
    width: 3%;
    color: black;
    font-weight: bold;
  }
  .colEstado {
    font-size: xx-small;
    width: 7%;
    color: black;
    font-weight: bold;
  }
  .colHorarios {
    font-size: xx-small;
    color: black;
    font-weight: bold;
  }
  .colBotones {
    font-size: xx-small;
    width: 10%;
  }

}

/* xl */
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  .pdatatable {
    font-size: xx-small;
    margin-top: 3.7vw;
  }
  .ptoolbar {
    position: fixed;
    left: auto;
    top: 3.0vw;
    right: 0;
    width: 100%;
    padding: 1vw !important;
    z-index: 1000;
  }
  .colIndicativo {
    font-size: xx-small;
    width: 3%;
    padding-left: 2em;
    color: black;
    font-weight: 700;
  }
  .colAfiliado {
    font-size: xx-small;
    width: 8%;
    padding: 0;
    color: black;
    font-weight: 700;
    font-weight: bold;
  }
  .colColor {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: 700;
  }
  .colGrupo {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: 700;
  }
  .colDomicilio {
    font-size: xx-small;
    width: 18%;
    color: black;
    font-weight: 700;
  }
  .colLocalidad {
    font-size: xx-small;
    width: 8%;
    color: black;
    font-weight: 700;
  }
  .colNombre {
    font-size: xx-small;
    width: 10%;
    color: black;
    font-weight: 700;
  }
  .colMovil {
    font-size: xx-small;
    color: black;
    font-weight: 700;
  }
  .colEdad {
    font-size: xx-small;
    width: 3%;
    color: black;
    font-weight: 700;
  }
  .colEstado {
    font-size: xx-small;
    width: 7%;
    color: black;
    font-weight: 700;
  }
  .colHorarios {
    font-size: xx-small;
    color: black;
    font-weight: 700;
  }
  .colBotones {
    font-size: xx-small;
    width: 10%;
  }
}

@media screen and (max-width: 40em) {
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
    padding-top: 0;
    padding-bottom: 0;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
