/* OrderListDemo.css */

.orderlist-demo .product-item {
    display: flex;
    align-items: center;
    padding: 0;
    width: 100%;
}

.orderlist-demo .product-item img {
    width: 75px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1rem;
}

.orderlist-demo .product-item .product-list-detail {
    flex: 1 1 0;
}

.orderlist-demo .product-item .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.orderlist-demo .product-item .product-category-icon {
    vertical-align: middle;
    margin-right: 0rem;
}

.orderlist-demo .product-item .product-category {
    vertical-align: middle;
    line-height: 1;
}

@media screen and (max-width: 576px) {
    .orderlist-demo .product-item {
        flex-wrap: wrap;
    }

    .orderlist-demo .product-item .image-container {
        width: 100%;
        text-align: center;
    }

    .orderlist-demo .product-item img {
        margin: 0 0 0 0;
        width: 100px;
    }
}


.p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.0rem 0.5rem;
    margin: 0;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: transform 0.2s, box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 0.0rem 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.p-orderlist .p-orderlist-controls {
    display: none;
}

.p-width {
    margin: 0.2vw;
}