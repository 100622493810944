
.map__container {
  width: 10% ;
}

@keyframes fade { 
  from { opacity: 0.1; } 
}

.blinking {
  animation: fade .5s infinite alternate;
}

