/* SpeedDialDemo.css */


.p-speeddial-action {
  width: 2.5rem;
  height: 2.5rem;
  background: #4b5563;
  color: #fff;
}  

.speeddial-linear-demo .p-speeddial-direction-up {
  left: calc(50% - 2rem);
  bottom: 0;
}
.speeddial-linear-demo .p-speeddial-direction-down {
  left: calc(50% - 2rem);
  top: 0;
}
.speeddial-linear-demo .p-speeddial-direction-left {
  right: 0;
  top: calc(50% - 2rem);
}
.speeddial-linear-demo .p-speeddial-direction-right {
  left: 0;
  top: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-circle {
  top: calc(50% - 2rem);
  left: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-up {
  left: calc(50% - 2rem);
  bottom: 0;
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-down {
  left: calc(50% - 2rem);
  top: 0;
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-left {
  right: 0;
  top: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-right {
  left: 0;
  top: calc(50% - 2rem);
}
.speeddial-circle-demo
  .p-speeddial-quarter-circle.p-speeddial-direction-up-left {
  right: 0;
  bottom: 0;
}
.speeddial-circle-demo
  .p-speeddial-quarter-circle.p-speeddial-direction-up-right {
  left: 0;
  bottom: 0;
}
.speeddial-circle-demo
  .p-speeddial-quarter-circle.p-speeddial-direction-down-left {
  right: 0;
  top: 0;
}
.speeddial-circle-demo
  .p-speeddial-quarter-circle.p-speeddial-direction-down-right {
  left: 0;
  top: 0;
}
.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-left {
  left: 0;
  bottom: 0;
}
.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-right {
  right: 0;
  bottom: 0;
}
.speeddial-delay-demo .p-speeddial-direction-up {
  left: calc(50% - 2rem);
  bottom: 0;
}
.speeddial-mask-demo .p-speeddial-direction-up {
  right: 0;
  bottom: 0;
}
.leaflet-container a {
  background-color: white;
}


.tooltip {
  font-size: .7em;
  margin-left: -2.5em;
}
.tooltipmapa {
  font-size: .7em;
  margin-left: -5em;
}

.speeddial {
  position: fixed;
  right: 5.5vw;
  height: 350px;
  z-index: 10000;
  margin-top: 7vw;
}

.speeddialmapa {
  position: fixed;
  right: 5.5vw;
  height: 350px;
  z-index: 10000;
  margin-top: 3vw;
}