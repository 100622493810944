body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bordo {
  background-color: #ba6187;
  color: white;
}

.rojo {
  background-color: red;
  color: white;
}

.amarillo {
  background-color: yellow;
  color: black;
}

.verde {
  background-color: green;
  color: white;
}

.azul {
  background-color: blue;
  color: white;
}

.naranja {
  background-color: orange;
  color: black;
}

.fuccia {
  background-color: fuchsia;
  color: black;
}

.blanco {
  background-color: white;
  color: black;
}

.negro {
  background-color: black;
  color: white;
}

.celeste {
  background-color: aqua;
  color: black;
}

.inhabilitado {
  background-color: red;
  color: white;
}

.suspendido {
  background-color: orange;
  color: black;
}

.habilitado {
  background-color: green;
  color: white;
}


@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

.file-select {
  position: relative;
  display: inline-block;
}

.file-select::before {
  background-color: #5678ef;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  content: "Seleccionar"; /* testo por defecto */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.file-select input[type="file"] {
  opacity: 0;
  width: 200px;
  height: 32px;
  display: inline-block;
}

#src-file1::before {
  content: "Seleccionar Archivo";
}

/* Despachos */
.tooltip {
  font-size: .7em;
}

.tooltip-speeddial-despachos {
  font-size: .7em;
  margin-left: -2.5em;
}

@media screen and (max-width: 599px) {
  #dataTableBotones {
    margin-top: 18vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-bottom: 0.5vw;
  }
  #dataMapaBotones {
    margin-top: 10vw;
  }
  .logo {
    display: block;
    margin: auto;
    padding-top: 15vw;
  }
  .login {
    align-items: center;
    margin: 1em;
    width: 80%;
  }
}


@media screen and (min-width: 600px) and (max-width: 959px) {
  #dataTableBotones {
    margin-top: 10vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-bottom: 0.5vw;
  }
  #multiselect1 {
    margin-left: 0em;
    margin-right: 3em;
  }
  #multiselect2 {
    margin-left: -3em;
    margin-right: 3em;
  }
  #multiselect3 {
    margin-left: 3em;
    margin-right: 3em;
  }
  .logo {
    display: block;
    margin: auto;
  }
  .login {
    align-items: center;
    margin: 1em;
    width: 60%;
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  #dataTableBotones {
    margin-top: 7vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-bottom: 0.5vw;
  }
  #multiselect1 {
    margin-left: 0em;
    margin-right: 3em;
  }
  #multiselect2 {
    margin-left: -3em;
    margin-right: 3em;
  }
  #multiselect3 {
    margin-left: 3em;
    margin-right: 3em;
  }
  .logo {
    display: block;
    margin: auto;
  }
  .dialogTomaServicio {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .login {
    align-items: center;
    margin: 1em;
    width: 30%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  #dataTableBotones {
    margin-top: 4vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-bottom: 0.5vw;
  }
  #multiselect1 {
    margin-left: 0em;
    margin-right: 3em;
  }
  #multiselect2 {
    margin-left: -3em;
    margin-right: 3em;
  }
  #multiselect3 {
    margin-left: 3em;
    margin-right: 3em;
  }
  .logo {
    display: block;
    margin: auto;
  }
  .dialogTomaServicio {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .login {
    align-items: center;
    margin: 1em;
    width: 30%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 5000px) {
  #dataTableBotones {
    margin-top: -1vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-bottom: 0.5vw;
  }
  #multiselect1 {
    margin-left: 0em;
    margin-right: 3em;
  }
  #multiselect2 {
    margin-left: -3em;
    margin-right: 3em;
  }
  #multiselect3 {
    margin-left: 3em;
    margin-right: 3em;
  }
  .logo {
    display: block;
    margin: auto;
  }
  .dialogTomaServicio {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .login {
    align-items: center;
    margin: 1em;
    width: 30%;
  }
}
